import { is } from 'date-fns/locale'
import { useCustomerCarFilterList } from '../hooks/useCustomerCarFilterList'
import { useGetCustomersWithFilters } from '../queries'
import Select from 'react-select'
import { useState } from 'react'

export function CustomerList({ customers, customerFilter, yearModel }) {
  const { data, isLoading } = useGetCustomersWithFilters()
  const thisYear = new Date().getFullYear()
  const [filter, setFilter] = useState(null)
  const [hovered, setHovered] = useState(null)
  if (isLoading) {
    return (
      <div>
        <span>Laster...</span>
      </div>
    )
  }

  if (data) {
    const customerList = useCustomerCarFilterList(
      data,
      customers,
      customerFilter,
      yearModel,
    )

    const listOfUniqueCarFilters = customerList
      .flatMap((customer) => {
        return customer.filters.map((filter) => {
          return {
            id: filter.id,
            name: filter.name,
          }
        })
      })
      .filter((filter, index, self) => {
        return index === self.findIndex((t) => t.id === filter.id)
      })

    return (
      <div className="flex flex-col gap-4  text-xs">
        <h1 className=" text-lg">Kundefilter</h1>
        <div className="flex flex-col gap-2">
          <label htmlFor="car-filter-per-cusomter">Velg bilfilter</label>
          <Select
            id="car-filter-per-customer"
            styles={{
              width: '400px',
            }}
            className="text-xs rounded-md widthSmall text-black pb-4"
            options={[
              { id: 'default', name: '--' },
              ...(listOfUniqueCarFilters || []),
            ]}
            placeholder="Velg bilfilter"
            getOptionValue={(option) => `${option['id']}`}
            getOptionLabel={(option) => `${option['name']}`}
            components={{
              IndicatorSeparator: () => null,
            }}
            value={filter}
            onChange={(e) => {
              if (e && e.id !== 'default') {
                return setFilter(e)
              }
              setFilter(null)
            }}
          ></Select>
          {filter && (
            <button
              onClick={() => setFilter(null)}
              className="flex text-blue-500 hover:underline text-xs"
            >
              Nullstill filter
            </button>
          )}
        </div>
        <table>
          <thead>
            <tr className="text-left">
              <th>#ID</th>
              <th>Navn</th>
              <th>Filtre</th>
              <th>Eldre filtre</th>
            </tr>
          </thead>
          <tbody className="">
            {customerList.map((customer, index) => {
              const customerHasFilter = customer.filters.some((el) => {
                return el.id === filter?.id
              })
              if (filter && !customerHasFilter) return null
              return (
                <tr key={customer.id} className=" border-2 text-xs">
                  <td className="pr-8 pl-2">#{customer.id}</td>
                  <td>{customer.name}</td>
                  <td>
                    <div className="flex flex-wrap gap-2 w-full py-4">
                      {customer.filters.map((filter, index) => {
                        const isPastThisYear =
                          filter.modelYear < new Date().getFullYear()
                        if (isPastThisYear) return null
                        const hoveredID =
                          customer.id === hovered?.customer &&
                          filter.id === hovered?.filter
                        return (
                          <div
                            onMouseEnter={() =>
                              setHovered({
                                customer: customer.id,
                                filter: filter.id,
                              })
                            }
                            onMouseLeave={() => setHovered(false)}
                            key={filter.id}
                            className={`text-xs relative text-left h-8 flex items-center justify-start overflow-ellipsis text-white font-bold border-2 px-4 rounded-lg ${'bg-blue-200 border-blue-500 text-blue-500'}`}
                          >
                            {hoveredID && (
                              <span className="absolute -top-12 left-0 rounded-md whitespace-nowrap bg-white text-black px-4 py-1 border-2 border-gray-500">
                                {filter.name}
                              </span>
                            )}
                            <span className="textEllipsis">{filter.name}</span>
                          </div>
                        )
                      })}
                    </div>
                  </td>
                  <td>
                    <div className="flex flex-wrap gap-2 w-full py-4">
                      {customer.filters.map((filter) => {
                        const isPastThisYear =
                          filter.modelYear < new Date().getFullYear()
                        if (!isPastThisYear) return null
                        const hoveredID =
                          customer.id === hovered?.customer &&
                          filter.id === hovered?.filter
                        return (
                          <div
                            onMouseEnter={() =>
                              setHovered({
                                customer: customer.id,
                                filter: filter.id,
                              })
                            }
                            onMouseLeave={() => setHovered(false)}
                            key={filter.id}
                            className={`text-xs relative text-left h-8 flex items-center justify-start overflow-ellipsis text-white font-bold border-2 px-4 rounded-lg ${'bg-red-200 border-red-500 text-red-500'}`}
                          >
                            {hoveredID && (
                              <span className="absolute -top-12 left-0 rounded-md whitespace-nowrap bg-white text-black px-4 py-1 border-2 border-gray-500">
                                {filter.name}
                              </span>
                            )}
                            <span className="textEllipsis">{filter.name}</span>
                          </div>
                        )
                      })}
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
  return null
}
