import axios from 'axios'
import { useQuery } from 'react-query'

export function useGetCustomersWithFilters() {
  const data = useQuery({
    queryKey: ['customers-filters'],
    refetchInterval: 20000,
    queryFn: async () =>
      await axios
        .get('/api/customer-filter-maps/customers-filters')
        .then((res) => res.data),
  })

  return data
}
