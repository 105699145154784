import Select from 'react-select'
import styled from 'styled-components'

export function SearchFilter({
  customers,
  customerSet,
  customer,
  yearModel,
  yearModelSet,
}) {
  function allYearsFromThisYearAndBackThree() {
    const currentYear = new Date().getFullYear() + 1
    const years = []
    for (let i = 0; i < 4; i++) {
      years.push({ value: currentYear - i, label: currentYear - i })
    }
    return years
  }
  const yearsOptions = allYearsFromThisYearAndBackThree()
  function resetAllFilters() {
    customerSet(null)
    yearModelSet(null)
  }
  return (
    <div className="flex flex-col gap-4">
      <div className="w-full pt-8 flex gap-4">
        <div className="flex flex-col gap-2 w-56">
          <label className="text-xs" htmlFor="yearSelect">
            Navn eller kundenr.
          </label>
          <Select
            id="customer"
            className="text-xs rounded-md text-black"
            options={[{ id: 'default', name: '--' }, ...(customers || [])]}
            placeholder="Velg kunde"
            getOptionValue={(option) => `${option['id']}`}
            getOptionLabel={(option) => `${option['name']}`}
            components={{
              IndicatorSeparator: () => null,
            }}
            value={customer}
            onChange={(e) => {
              if (e && e.id !== 'default') {
                return customerSet(e)
              }
              customerSet(null)
            }}
          ></Select>
        </div>
        <div className="flex flex-col gap-2 w-56">
          <label className="text-xs" htmlFor="yearSelect">
            Årsmodell
          </label>
          <Select
            id="yearSelect"
            className="text-xs"
            options={[{ label: '--', value: 'default' }, ...yearsOptions]}
            getOptionValue={(option) => `${option['value']}`}
            getOptionLabel={(option) => `${option['label']}`}
            placeholder="Velg årsmodell"
            value={yearModel}
            components={{
              IndicatorSeparator: () => null,
            }}
            onChange={(e) => {
              if (e && e.value !== 'default') {
                return yearModelSet(e)
              }
              yearModelSet(null)
            }}
          ></Select>
        </div>
      </div>
      {(customer || yearModel) && (
        <button
          onClick={() => resetAllFilters()}
          className="flex text-blue-500 hover:underline text-xs"
        >
          Nullstill filter
        </button>
      )}
    </div>
  )
}
