import { useMemo } from 'react'
import { useQuery } from 'react-query'
import axios from 'axios'

export const useFilters = ({
  customer,
  showUnavailableCustomerFilters,
  showUnavailableEquipmentFilters,
  yearModel,
}) => {
  const { data: carFilters } = useQuery(['car-filter-db-data'], async () => {
    const res = await axios.get('/api/car-filters')
    return res.data
  })

  const { data: customerMaps, isLoading } = useQuery(
    [customer, 'filter-by-customer-id'],
    async () => {
      const res = await axios.get(
        `/api/customer-filter-maps?customerId=${customer.id}`,
      )
      return res.data
    },
    { enabled: !!customer },
  )

  const equipmentFilters = useMemo(() => {
    if (!carFilters || isLoading) {
      return null
    }
    if (customer && yearModel) {
      const allowedCustomers = carFilters
        .filter((carFilter) => !carFilter.allowed_by_all_customers)
        .map((carFilter) =>
          customerMaps.some((cMap) => cMap.filter_id === carFilter.id)
            ? {
                ...carFilter,
                available: true,
              }
            : { ...carFilter, available: false },
        )
      const filterByYear = allowedCustomers.filter(
        (car) => car.configuration.carYear === yearModel.value.toString(),
      )
      return filterByYear
    }

    if (customer && customerMaps) {
      // customers with a mapping towards an equipment filter have access to the filter
      return carFilters
        .filter((carFilter) => carFilter.allowed_by_all_customers)
        .map((carFilter) =>
          customerMaps.some((cMap) => cMap.filter_id === carFilter.id)
            ? {
                ...carFilter,
                available: false,
              }
            : { ...carFilter, available: true },
        )
    }
    if (yearModel) {
      return carFilters.filter(
        (carFilter) =>
          carFilter.configuration.carYear === yearModel.value.toString(),
      )
    }

    return carFilters.filter((carFilter) => carFilter.allowed_by_all_customers)
  }, [
    carFilters,
    customerMaps,
    showUnavailableEquipmentFilters,
    customer,
    yearModel,
  ])

  const customerFilters = useMemo(() => {
    if (!carFilters || isLoading) {
      return null
    }
    if (customer && yearModel) {
      const allowedCustomers = carFilters
        .filter((carFilter) => !carFilter.allowed_by_all_customers)
        .map((carFilter) =>
          customerMaps.some((cMap) => cMap.filter_id === carFilter.id)
            ? {
                ...carFilter,
                available: true,
              }
            : { ...carFilter, available: false },
        )
      const filterByYear = allowedCustomers.filter(
        (car) => car.configuration.carYear === yearModel.value.toString(),
      )
      return filterByYear
    }

    if (customer && customerMaps) {
      // customers with a mapping towards a customer filter are excluded from the car filter

      return carFilters
        .filter((carFilter) => !carFilter.allowed_by_all_customers)
        .map((carFilter) =>
          customerMaps.some((cMap) => cMap.filter_id === carFilter.id)
            ? {
                ...carFilter,
                available: true,
              }
            : { ...carFilter, available: false },
        )
    }
    if (yearModel) {
      return carFilters.filter(
        (carFilter) =>
          carFilter.configuration.carYear === yearModel.value.toString(),
      )
    }

    return carFilters.filter((carFilter) => !carFilter.allowed_by_all_customers)
  }, [
    carFilters,
    customerMaps,
    showUnavailableCustomerFilters,
    customer,
    yearModel,
  ])

  return {
    carFilters,
    equipmentFilters,
    customerFilters,
  }
}
