import { useMemo } from "react";
import formatDate from "../../utils/dateFormatter";
import { Header } from "../CarFilterCustomerMap";
import { Table } from "./components/Table";
import RadioSlide from "./components/RadioSlide";
import styled from "styled-components";

const Columns = ({ selectedCustomer }) => {
  return useMemo(
    () => [
      {
        Header: "Navn",
        accessor: "label",
      },
      {
        Header: "Merke",
        accessor: "configuration.make",
      },
      {
        Header: "Modell",
        accessor: "configuration.model",
      },
      {
        Header: "Årsmodell",
        accessor: "configuration.carYear",
      },
      {
        Header: "Karosseri",
        accessor: "configuration.body",
      },
      {
        Header: "Opprettet dato",
        accessor: (row) => formatDate(new Date(row.created_at.split("T")[0])),
      },
      {
        Header: "Oppdatert dato",
        accessor: (row) =>
          row.updated_at
            ? formatDate(new Date(row.updated_at.split("T")[0]))
            : "",
      },
      {
        Header: "Generisk",
        accessor: (row) => row.configuration.isGenericFilter ? "Ja" : "Nei"
      },
      ...(selectedCustomer ? [{
        Header: "Tilgjengelig",
        accessor: (row) => (row.available ? "Ja" : "Nei"),
      }] : []),
    ], [selectedCustomer]
  );
};

export default ({
  customer,
  customerFilters,
  customers,
  createCarFilterHandler,
  menuSelectionHandler,
  tableHandlers,
  showCarHandler,
  handleShowUnavailableCustomerFilters,
  showUnavailableCustomerFilters,
}) => {
  const { getSubRowComponent, selectedRows, toggleSelectedRow } = tableHandlers;
  const columns = Columns({ selectedCustomer: !!customer });

  return (
    <div>
      <TableInfoContainer>
        <div className="flex flex-col gap-2">
          <Header>Bilfiltre</Header>
        
                    <span className="text-sm">
Filtre som bare gjelder utlistede kunder.</span>
        </div>
        {customer && (
          <RadioSlide
            key={"customer-filters"}
            toggle={() =>
              handleShowUnavailableCustomerFilters(
                !showUnavailableCustomerFilters
              )
            }
            isActive={showUnavailableCustomerFilters}
          >
            <span> Vis utilgjengelige filtre</span>
          </RadioSlide>
        )}
      </TableInfoContainer>
      {customerFilters && (
        <Table
          
          columns={columns}
          data={
            showUnavailableCustomerFilters
              ? customerFilters
              : customer
              ? customerFilters.filter((cf) => cf.available)
              : customerFilters
          }
          renderRowSubComponent={{
            level: "car-filter",
            onRowClick: ({ row }) => getSubRowComponent({ row, customers }),
            onActionClick: menuSelectionHandler,
          }}
          selectedRows={selectedRows}
          selectedRowsHandler={toggleSelectedRow}
          onRowSelected={showCarHandler}
        />
      )}
        <a
          style={{
            cursor: "pointer",
          }}
          className="bg-blue-500 cursor:pointer px-8 py-2 text-white text-sm hover:shadow-lg border-2 border-white hover:border-blue-500 hover:bg-white hover:text-blue-500 transition-all ease-in-out"
            onClick={() =>
              createCarFilterHandler({
                startCarFilter: true,
                type: "car-filter",
              })
            }
          >
           Opprett nytt bilfilter
          </a>
    </div>
  );
};

export const TableInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
