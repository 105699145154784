import React, { useState } from 'react'
import styled from 'styled-components'
import { Header } from '../CarFilterCustomerMap'
import CreateCarFilterConfiguration from './CreateCarFilterConfiguration'
import { useQuery } from 'react-query'
import axios from 'axios'
import CustomerTable from './components/CustomerTable'
import useCarFilterMutations from './hooks/useCarFilterMutations'
import { getCarFilterById } from './requests'
import { Colors } from '@flow/style'
import { DisplayCarConfig } from './index'
import ContextBasic from '../car-configuration/components/ContextBasic'
import CustomerFilters from './CustomerFilters'
import EquipmentFilters from './EquipmentFilters'
import Select, { createFilter } from 'react-select'
import { useFilters } from './hooks/useFilters'
import { SearchFilter } from './components/SearchFilter'
import { LoadingSpinner } from '../LoadingSpinner'
import { CustomerList } from './components/CustomerList'

const doFilter = createFilter

export default ({ ...rest }) => {
  // this useState is an object to avoid having to use multiple handlers for a single action in Table
  const [carFilterConfigurationView, carFilterConfigurationViewSet] = useState({
    startCarFilter: false,
  })
  const [selectedRows, selectedRowsSet] = useState([])
  const [customer, customerSet] = useState(null)
    const [yearModel, yearModelSet] = useState(null)

  const [showUnavailableCustomerFilters, showUnavailableCustomerFiltersSet] =
    useState(false)
  const [showUnavailableEquipmentFilters, showUnavailableEquipmentFiltersSet] =
    useState(false)

  const { createFilter, updateFilter, deleteFilter } = useCarFilterMutations()

  const { data: customers, isLoading } = useQuery(['customers'], async () => {
    const res = await axios.get('/api/core/customers')
    return res.data
  })


  const { carFilters, equipmentFilters, customerFilters } = useFilters({
    customer,
    showUnavailableCustomerFilters,
    showUnavailableEquipmentFilters,
    yearModel,
  })

  const handleCreateCarFilter = async ({
    type,
    startCarFilter = false,
    id = null,
  }) => {
    if (!startCarFilter) {
      return carFilterConfigurationViewSet({ startCarFilter: false, type })
    }
    if (id) {
      const { configuration, label } = await getCarFilterById(id)
      return carFilterConfigurationViewSet({
        startCarFilter,
        configuration,
        label,
        id,
        type,
      })
    }
    carFilterConfigurationViewSet({ startCarFilter, type })
  }

  const handleSaveCarFilter = ({ label, configuration, id, type }) => {
    if (!id) {
      createFilter({ label, configuration, type })
    } else {
      updateFilter({ id, label, configuration, type })
    }
    carFilterConfigurationViewSet({ startCarFilter: false })
  }

  const handleToggleAllCustomers = (carFilterId) => {
    const currentCarFilter = carFilters.find(
      (carFilter) => carFilter.id === carFilterId,
    )
    if (currentCarFilter) {
      updateFilter({
        id: carFilterId,
        allowedByAllCustomers: !currentCarFilter.allowed_by_all_customers,
      })
    }
  }

  const handleCarFilterMenuSelection = (carFilterRow, type, carFilterType) => {
    switch (type) {
      case 'edit':
        handleCreateCarFilter({
          startCarFilter: true,
          id: carFilterRow.original.id,
          configuration: {
            ...carFilterRow.original.configuration,
            type: carFilterType,
          },
        })
        break
      case 'delete':
        // todo a confirm popup-thingy whereupon confirming deletes the filter
        alert('Er du sikker på at du vil slettet filteret?')
        deleteFilter(carFilterRow.original.id)
        break
      default:
        break
    }
  }

  const toggleSelectedRow = (parentId, row) => {
    if (selectedRows.some((selRow) => selRow.parentId === row.original.id)) {
      selectedRowsSet(
        selectedRows.filter(
          (subComponent) => subComponent.parentId !== parentId,
        ),
      )
    } else {
      selectedRowsSet([{ parentId, subRow: row }])
    }
  }

  const getSubRowComponent = ({ row, customers }) => {
    const currentRow = carFilters.find(
      (carFilter) => carFilter.id === row.original.id,
    )
    const allowedByAll = currentRow && currentRow.allowed_by_all_customers

    return (
      <CustomerTable
        customer={customer}
        carFilter={row.original}
        data={customers}
        selectedRows={selectedRows}
        allowedByAllCustomers={allowedByAll}
        handleAllowedByAllCustomers={handleToggleAllCustomers}
      />
    )
  }

  const [carConfig, carConfigSet] = useState(null)
  const [filterIdClicked, setFilterIdClicked] = useState(null)
  function handleFilterClick(id) {
    if(filterIdClicked === id) {
      setFilterIdClicked(null)
    } else {
    setFilterIdClicked(id)
  }
}
  const showFilter = (row) => {
    if (carConfig && carConfig.id === row.original.id) {
      carConfigSet(null)
    } else {
      carConfigSet(row.original)
    }
  }

  if (carFilterConfigurationView.startCarFilter) {
    return (
      <CreateCarFilterConfiguration
        {...rest}
        carFilterId={carFilterConfigurationView.id}
        data={
          carFilterConfigurationView.configuration
            ? {
                type: carFilterConfigurationView.type,
                configuration: carFilterConfigurationView.configuration,
                label: carFilterConfigurationView.label,
              }
            : { type: carFilterConfigurationView.type }
        }
        createCarFilterHandler={handleCreateCarFilter}
        saveCarFilterHandler={handleSaveCarFilter}
      />
    )
  }

  if (isLoading) {
    return (
      <div className="min-w-full gap-4 flex-col pb-32 flex items-center justify-center min-h-screen">
        <div className='h-8 w-8  rounded-full loader animate-spin'/>
        <span className='text-xs'>Laster inn...</span>
      </div>
    )
  }

  const onlyCarFilters = customerFilters?.filter((cf) => cf.configuration?.type === 'car-filter')
  const onlyEquipmentFilters = customerFilters?.filter((cf) => cf.configuration?.type === 'equipment-filter')

  
  return (
    <Wrapper>
      <Left>
        <div>
          <SearchFilter
            yearModel={yearModel}
            yearModelSet={yearModelSet}
            customer={customer}
            customerSet={customerSet}
            customers={customers}
          />
        </div>
        <CustomerFilters
          customer={customer}
          filterByCustomer={customer}
          customerFilters={onlyCarFilters}
          customers={customers}
          createCarFilterHandler={handleCreateCarFilter}
          menuSelectionHandler={handleCarFilterMenuSelection}
          tableHandlers={{
            getSubRowComponent,
            selectedRows,
            toggleSelectedRow,
          }}
          showCarHandler={showFilter}
          showUnavailableCustomerFilters={showUnavailableCustomerFilters}
          handleShowUnavailableCustomerFilters={
            showUnavailableCustomerFiltersSet
          }

        />
        <EquipmentFilters
          customer={customer}
          equipmentFilters={onlyEquipmentFilters}
          customers={customers}
          createCarFilterHandler={handleCreateCarFilter}
          menuSelectionHandler={handleCarFilterMenuSelection}
          tableHandlers={{
            getSubRowComponent,
            selectedRows,
            toggleSelectedRow,
          }}
          showCarHandler={showFilter}
          showUnavailableEquipmentFilters={showUnavailableEquipmentFilters}
          handleShowUnavailableEquipmentFilters={
            showUnavailableEquipmentFiltersSet
          }
        />
        <CustomerList
          customers={customers}
          customerFilter={customer}
          yearModel={yearModel}
        />
      </Left>
      {!!carConfig && (
        <Right>
          <Header style={{ margin: '18px' }}>
            Bilfilter {carConfig.label}
          </Header>
          <InfoArea>
            <DisplayCarConfig
              make={carConfig.configuration.make}
              model={carConfig.configuration.model}
              year={carConfig.configuration.carYear}
              engine={carConfig.configuration.engineName}
              body={carConfig.configuration.bodyName}
              accessories={carConfig.configuration.accessoriesList}
            />
          </InfoArea>
          <PictureArea>
            {carConfig.configuration.imageUrl ? (
              <img src={carConfig.configuration.imageUrl} alt="Car" />
            ) : (
              <div />
            )}
          </PictureArea>
          <PriceArea>
            <ContextBasic
              title="Grunnpris bil"
              value={carConfig.configuration.retailPrice + ' kr'}
              size="small"
              input={false}
            />
            <ContextBasic
              title="Tilleggsutstyr"
              value={carConfig.configuration.accessoriesPrice + ' kr'}
              size="small"
              input={false}
            />
            <ContextBasic
              title="Totalpris"
              value={carConfig.configuration.totalPrice + ' kr'}
              size="big"
              input={false}
            />
          </PriceArea>
        </Right>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.4fr;
  height: 100%;
  grid-gap: 1rem;
  width: 100%;
`

const Left = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 50px;
`

const Right = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border-left: 1px solid ${Colors.Grey3};
`

const PictureArea = styled.div`
  display: flex;
  justify-content: center;
  margin: 10%;

  img {
    max-width: 75%;
  }
`

const InfoArea = styled.div`
  padding-left: 24px;
  height: auto;
  flex-grow: 2;
  overflow: auto;
  border-bottom: 1px solid ${Colors.Grey3};
`

const PriceArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px 0 15px 0;
  display: flex;
`

const StyledInput = styled(Select)`
  width: 250px;
  margin: 2px 0 12px 0;
  border: 1px solid ${Colors.Grey2};
  border-radius: 4px;
`
