export function useCustomerCarFilterList(
  list,
  customers,
  customerFilter,
  yearModel,
) {
  const mergedData = list
    .map((customer) => {
      const match = customers.find((info) => info.id === customer.customerId)
      if (match) {
        return {
          id: match.id,
          name: match.name,
          filters: customer.filters,
        }
      }
    })
    .filter(Boolean)

  const sortByAcendingName = mergedData.sort((a, b) => {
    if (a.name < b.name) {
      return -1
    }
    if (a.name > b.name) {
      return 1
    }
    return 0
  })

  let customerList = sortByAcendingName
  if (customerFilter && yearModel) {
    const byCustomer = sortByAcendingName.filter(
      (customer) => customer.id === customerFilter.id,
    )
    const checkIfFilterInListHaveSameYear = byCustomer.map((cus) => {
      return {
        ...cus,
        filters: cus.filters.filter(
          (filter) => filter.modelYear === yearModel.value.toString(),
        ),
      }
    })
    customerList = checkIfFilterInListHaveSameYear
  }

  if (customerFilter && !yearModel) {
    customerList = sortByAcendingName.filter((customer) => {
      return customer.id === customerFilter.id
    })
  }

  if (yearModel && !customerFilter) {
    const list = customerList
    const checkIfFilterInListHaveSameYear = list.map((cus) => {
      return {
        ...cus,
        filters: cus.filters.filter(
          (filter) => filter.modelYear === yearModel.value.toString(),
        ),
      }
    })
    customerList = checkIfFilterInListHaveSameYear
  }
  return customerList
}
